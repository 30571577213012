import React, { Component } from 'react';
import Cookies from 'universal-cookie';

class PrivacyNotice extends Component {
	cookies = new Cookies();

	constructor(props) {
		super(props);

		let cookieSaved = this.cookies.get('eucookie');
		let showComponent = cookieSaved === 'eucookie' ? false : true;

		this.handleNameChange = this.handleNameChange.bind(this);
		this.state = {
			stateCookieSaved: cookieSaved,
			stateShowComponent: showComponent
		};
	}

	componentDidUpdate() {
		if (this.cookieSaved === '') {
		}
	}

	handleNameChange() {
		let currentDate = new Date();
		this.cookies.set('eucookie', 'eucookie', {
			path: '/',
			expires: new Date(
				currentDate.getFullYear() + 10,
				currentDate.getMonth(),
				currentDate.getDate()
			)
		});

		this.setState(state => ({
			stateCookieSaved: 'eucookie',
			stateShowComponent: false
		}));

		console.log('Entered funtion', 'Now');
	}

	render() {
		return this.state.stateShowComponent ? (
			<div className="privacy-notice">
				<div className="container-fluid">
					<div className="row">
						<div className="col">
							<p>
								We are using cookies to give you the best
								experience on our site. To find out more see our{' '}
								<a
									href="https://www.investec.com/en_gb/legal/privacy-policy.html"
									target="_blank"
									title="Privacy Policy"
									rel="noopener noreferrer"
								>
									Cookies Policy
								</a>
								. By continuing to use our website without
								changing the settings, you are agreeing to our
								use of cookies.
								<svg
									id="close"
									xmlns="http://www.w3.org/2000/svg"
									//id="Layer_1"
									width="28"
									height="28"
									viewBox="0 0 28 28"
									className=""
									onClick={this.handleNameChange}
								>
									<path d="M18.949 17.536L15.414 14l3.535-3.536a.999.999 0 1 0-1.414-1.414L14 12.586 10.464 9.05a.999.999 0 1 0-1.414 1.414L12.586 14 9.05 17.536a.999.999 0 1 0 1.414 1.414L14 15.414l3.535 3.536a.999.999 0 1 0 1.414-1.414z" />
									<path d="M22.485 5.515c4.679 4.68 4.679 12.291 0 16.971s-12.292 4.68-16.971 0-4.679-12.291 0-16.971 12.293-4.68 16.971 0m1.414-1.414c-5.468-5.467-14.331-5.468-19.799 0s-5.467 14.332 0 19.799c5.468 5.468 14.331 5.468 19.799 0s5.468-14.332 0-19.799z" />
								</svg>
							</p>
						</div>
					</div>
				</div>
			</div>
		) : (
			''
		);
	}
}

export default PrivacyNotice;
