import React, { Component } from 'react';

class Services extends Component {
    render() {
        return (
            <div className="container">
                <section className="row services">
                    <div className="col">

                        <h2>Integrated trading and settlements</h2>
                        <p>
                        As part of VU Live’s offering, you and your employees 
                        benefit from the following integrated services:
                        </p>

                        <ul id="services-tabs" className="nav nav-pills" role="tablist">
                            <li className="nav-item">
                                <a id="tab-brokerage" href="#services-brokerage" className="nav-link nav-link-brokerage active" data-toggle="pill" role="tab">
                                    <img src="./images/services_icon_brokerage.svg" alt="Trade Execution" />
                                    <span>Trade Execution</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="tab-custody" href="#services-custody" className="nav-link nav-link-services" data-toggle="pill" role="tab">
                                    <img src="./images/services_icon_custody.svg" alt="Custody" />
                                    <span>Custody</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="tab-banking" href="#services-banking" className="nav-link  nav-link-banking" data-toggle="pill" role="tab">
                                    <img src="./images/services_icon_banking.svg" alt="Payments &amp; FX" />
                                    <span>Payments &amp; FX</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="tab-ifrs" href="#services-ifrs" className="nav-link nav-link-ifrs" data-toggle="pill" role="tab">
                                    <img src="./images/services_icon_ifrs.svg" alt="Financial Reporting" />
                                    <span>Financial Reporting</span>
                                </a>
                            </li>
                            
                        </ul>

                        <div id="services-content" className="tab-content" role="tablist">
                            <div id="services-brokerage" className="card card-brokerage tab-pane fade show active" role="tabpanel" aria-labelledby="tab-brokerage">
                                <div className="card-header" role="tab" id="heading-brokerage">
                                    <a data-toggle="collapse" href="#collapse-brokerage" aria-expanded="true" aria-controls="collapse-brokerage">
                                        <img src="./images/services_icon_brokerage.svg" alt="Trade Execution" />
                                        Trade Execution
                                    </a>
                                </div>
                                <div id="collapse-brokerage" className="collapse show" data-parent="#services-content" role="tabpanel" aria-labelledby="heading-brokerage">
                                    <div className="card-body">
                                        <h3>Trade Execution</h3>
                                        <p>We offer a traditional voice brokerage service to you, your trust and your employees.</p>
                                        <p>Through VU Live, your employees have access to real-time market pricing and information which is directly linked to a team of professional corporate and executive dealers. All orders are sensitively managed. Where required, you and your employees have direct access to the dealing team for general market guidance and to discuss execution strategies.</p>
                                        <p>Investec's institutional reach and knowledge is designed to ensure minimum share price disruption and optimal pricing for you.</p>
                                    </div>
                                </div>
                            </div>
                            <div id="services-custody" className="card card-custody tab-pane fade" role="tabpanel" aria-labelledby="tab-custody">
                                <div className="card-header" role="tab" id="heading-custody">
                                    <a className="collapsed" data-toggle="collapse" href="#collapse-custody" aria-expanded="false" aria-controls="collapse-custody">
                                        <img src="./images/services_icon_custody.svg" alt="Custody" />
                                        Custody
                                    </a>
                                </div>
                                <div id="collapse-custody" className="collapse" data-parent="#services-content" role="tabpanel" aria-labelledby="heading-custody">
                                    <div className="card-body">
                                        <h3>Custody</h3>
                                        <p>Your company and trustee's securities are held in a client designated account at Investec. We provide proxy voting, dividend reinvestment, electronic transfer and dematerialisation services.</p>
                                    </div>
                                </div>
                            </div>
                            <div id="services-banking" className="card card-banking tab-pane fade" role="tabpanel" aria-labelledby="tab-banking">
                                <div className="card-header" role="tab" id="heading-banking">
                                    <a className="collapsed" data-toggle="collapse" href="#collapse-banking" aria-expanded="false" aria-controls="collapse-banking">
                                    <img src="./images/services_icon_banking.svg" alt="Payments &amp; FX" />
                                        Payments &amp; FX
                                    </a>
                                </div>
                                <div id="collapse-banking" className="collapse" role="tabpanel" data-parent="#services-content" aria-labelledby="heading-banking">
                                    <div className="card-body">
                                        <h3>Payments &amp; FX</h3>
                                        <p>Currency trades are executed directly with Investec's corporate treasury desk at interbank market rates. Payments are made via SWIFT at no extra charge. This ensures a streamlined process for you and your employees and payments are generally processed on settlement dates.</p>
                                    </div>
                                </div>
                            </div>
                            <div id="services-ifrs" className="card card-ifrs tab-pane fade" role="tabpanel" aria-labelledby="tab-ifrs">
                                <div className="card-header" role="tab" id="heading-ifrs">
                                    <a className="collapsed" data-toggle="collapse" href="#collapse-ifrs" aria-expanded="false" aria-controls="collapse-ifrs">
                                    <img src="./images/services_icon_ifrs.svg" alt="Financial Reporting" />
                                        Financial Reporting
                                    </a>
                                </div>
                                <div id="collapse-ifrs" className="collapse" role="tabpanel" data-parent="#services-content" aria-labelledby="heading-ifrs">
                                    <div className="card-body">
                                        <h3>Financial Reporting</h3>
                                        <p>We offer you a complete IFRS2 solution that covers:</p>
                                        <ul>
                                            <li>Advisory: liaison with your auditors, automated valuations and performance condition monitoring</li>
                                            <li>Amortisation: calculating the IFRS charges based on valuations, holdings and employee cost centre information</li>
                                            <li>Reporting: Annual Financial Statement reporting for equity instruments and detailed calculation reports</li>
                                            <li>Forecasting: Assisting you to determine future charges</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        );
    }
}

export default Services;