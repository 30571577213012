import React, { Component } from 'react';
//import Video from './Video';
import Intro from './Intro';
import WhyUse from './WhyUse';
import Services from './Services';
import VuMeaning from './VuMeaning';
import Numbers from './Numbers';
import Testimonials from './Testimonials';
import Contact from './Contact';
import Config from './config.json';

class Home extends Component {
	render() {
		return (
			<div>
				{ /*<Video /> */ }
				<Intro />
				<WhyUse />
				<Services />
				<VuMeaning />
				<Numbers />
				<Testimonials />
				<Contact rkey={Config.recaptcha.key} />
			</div>
		);
	}
}

export default Home;
