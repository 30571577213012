import React, { Component } from 'react';

class VuMeaning extends Component {
	render() {
		return (
			<div className="container-fluid">
				<section className="row employees vu-bg">
					<div className="container">
						<h2>
							So what does VU Live mean
							<br />
							for you and your employees?
						</h2>
						<div className="row">
							<div className="col-md-6">
								<img
									className="img-fluid"
									src="./images/for_you.png" alt="for you"
								/>
								<div className="rectangle">
									<p className="for-you">For you</p>
									<ul>
										<li>
											Real-time access and control of
											share plan and participant
											information
										</li>
										<li>Self-service reporting suite</li>
										<li>
											PDMR and closed period management
											tools
										</li>
										<li>
											Easy tracking of leavers, joiners,
											performance conditions and dividends
										</li>
										<li>
											Full oversight and control of online
											approval processes
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-6">
								<img
									className="img-fluid"
									src="./images/for_your_employees.png" alt="your employees"
								/>
								<div className="rectangle">
									<p className="for-you">
										For your employees
									</p>
									<ul>
										<li>One view of all their plans</li>
										<li>
											Instant access to all plan
											documentation
										</li>
										<li>
											Online corporate action elections
											and award acceptances, without the
											paperwork
										</li>
										<li>
											An interactive dealing service,
											real-time
										</li>
										<li>
											Export personal data, statements and
											other reports online
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default VuMeaning;
