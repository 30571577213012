import React, { Component } from 'react';

class WhyUse extends Component {
	render() {
		return (
			<div className="container-fluid">
				<section className="row why">
					<div className="container">
						<h2>Why use VU Live?</h2>
						<div className="row">
							<div className="col-md why-1">
								<img
									className="mx-auto"
									src="./images/round-arrow.png" alt="arrow"
								/>
								<p>
									<strong>Intuitive and flexible</strong>
								</p>
								<p>
									VU Live enables employees to have one view
									of all their plans, with access for both you
									and your employees to real-time information
									and a vast suite of live reports.
								</p>
							</div>
							<div className="col-md why-2">
								<img
									className="mx-auto"
									src="./images/round-arrow-2.png" alt="arrow"
								/>
								<p>
									<strong>
										Real-time dealing and order management
									</strong>
								</p>
								<p>
									Our integrated dealing service helps
									mitigate dependency risk of third-party
									brokers which means fairer market prices for
									your employees.
								</p>
							</div>
							<div className="col-md why-3">
								<img
									className="mx-auto"
									src="./images/circles.png" alt="circle"
								/>
								<p>
									<strong>Straight Through Processing</strong>
								</p>
								<p>
									Completed transactions are automatically settled 
									and funds are directly paid to your relevant 
									company, payroll and/or employee banks accounts.
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default WhyUse;
