import React, { Component } from 'react';

class Numbers extends Component {
    render() {
        return (
            <div className="container-fluid">
                <section className="row numbers">
                    <div className="col">
                        <div className="container">
                            <h2>Our business in numbers</h2>
                            <div className="row stats">
                                <div className="col-md-4 col-lg-3 mb-4 mb-md-0">
                                    <p className="stat">500+</p>
                                    <p>Serviced share plans</p>
                                </div>
                                <div className="col-md-4 col-lg-3 offset-lg-1 mb-4 mb-md-0">
                                    <p className="stat">850,000</p>
                                    <p>Current participants</p>
                                </div>
                                <div className="col-md-4 col-lg-3 offset-lg-1">
                                    <p className="stat">180+</p>
                                    <p>Corporate clients</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Numbers;