import React, { Component } from 'react';

class Testimonials extends Component {
    render() {
        return (
            <div className="container-fluid">
                <section className="row testimonials">
                    <div className="col">
                        <div className="container">
                            <h2>Testimonials</h2>

                            <div id="testimonials-carousel" className="carousel slide testimonials-carousel" data-ride="carousel">

                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-interval="10000">
                                        <div className="row align-items-center">
                                            <div className='col-lg-4 d-none d-lg-block d-xl-block'>
                                                <img src="./images/testimonial_halma.png" alt="" className="img-fluid" />
                                            </div>
                                            <div className='col-lg-8'>
                                                <p>
                                                    This solution has been a step-change for Halma and has been really 
                                                    well received by our participants. The share award and vesting cycle 
                                                    always creates a peak in our workload but thanks to Investec we can 
                                                    deliver this process in the most efficient way.
                                                </p>
                                                <p className="testimonial-source">Mark Jenkins, Company Secretary, Halma</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-interval="10000">
                                        <div className="row align-items-center">
                                            <div className='col-lg-4 d-none d-lg-block d-xl-block'>
                                                <img src="./images/testimonial_blueprism.png" alt="" className="img-fluid" />
                                            </div>
                                            <div className='col-lg-8'>
                                                <p>
                                                    The relationship we have with Investec is totally collaborative, our 
                                                    open and honest conversations make for a productive partnership. 
                                                    VU Live is easy to use, creative, dynamic and a massive time saver. 
                                                    Colleagues don't come to us to perform basic actions linked with their 
                                                    share awards anymore, which is a resource bonus. The Investec platform 
                                                    is a great testament to the team - it has meant that we can easily 
                                                    grant and manage awards in over 15 different countries.
                                                </p>
                                                <p className="testimonial-source">Company Secretary, Blue Prism</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-interval="10000">
                                        <div className="row align-items-center">
                                            <div className='col-lg-4 d-none d-lg-block d-xl-block'>
                                                <img src="./images/testimonial_xppower.png" alt="" className="img-fluid" />
                                            </div>
                                            <div className='col-lg-8'>
                                                <p>
                                                    The front-end of VU Live is very intuitive and functional, as a result 
                                                    colleagues can self-serve and the process is very rapid. Trading is 
                                                    straightforward, which alleviates the administrative burden previously 
                                                    experienced in large volume trades. Wish we had this implemented sooner!
                                                </p>
                                                <p className="testimonial-source">CEO, XP power</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-target="#testimonials-carousel" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-target="#testimonials-carousel" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Testimonials;