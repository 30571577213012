import React, { Component } from 'react';

class Disclaimer extends Component {
	render() {
		return (
			<div className="container disclaimer">
				<span id="real-time" />
				<span className="why" />
				<span className="dsp" />
				<span className="employees" />
				<span className="contact" />

				<div className="row">
					<div className="col">
						<h1>Disclaimer</h1>
						<p>
							Dealing services will be provided by
							Investec.
						</p>
						<p>
							Investec Bank plc is authorised by the Prudential
							Regulation Authority and regulated by the Financial
							Conduct Authority and the Prudential Regulation
							Authority. It is a member of the London Stock
							Exchange Registered under Financial Services
							Register reference 172330. Investec Bank plc is a
							limited company registered in England and Wales at
							Companies House. Our registered office is 30 Gresham
							Street, London EC2V 7QP and our registered number is
							00489604.
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default Disclaimer;
