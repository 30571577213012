import React, { Component } from 'react';
import axios from 'axios';
import ContactMessageSent from './Contact_MessageSent';
import Config from './config.json';

let recaptchaInstance;

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stateCompany: '',
			stateFirstName: '',
			stateLastName: '',
			stateEmail: '',
			stateContactNumber: '',
			stateMessage: '',
			stateByEmail: false,
			stateByTelephone: false,
			stateRecaptchaToken: '',
			stateMessageSent: false,
			stateMounter: false,
			stateKey: props.rkey
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	executeCaptcha = event => {
		recaptchaInstance.execute();
	};

	verifyCallback = response => {
		this.setState({
			stateRecaptchaToken: response
		});
	};

	componentWillMount() {
		let key = this.state.stateKey;
		window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(key, {
					action: 'VU_Live_com_Contact'
				})
				.then(token => {
					this.setState({
						stateRecaptchaToken: token
					});
				});
		});
	}

	handleChange(event) {
		const target = event.target;
		const value =
			target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleSubmit(event) {
		let config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		axios
			.post(
				Config.api.base +
					Config.api.contact.endpoint +
					'?response=' +
					this.state.stateRecaptchaToken,
				{
					company: this.state.stateCompany,
					firstName: this.state.stateFirstName,
					lastName: this.state.stateLastName,
					email: this.state.stateEmail,
					contactNumber: this.state.stateContactNumber,
					message: this.state.stateMessage,
					byEmail: this.state.stateByEmail,
					byTelephone: this.state.stateByTelephone
				},
				config
			)
			.then(function(response) {})
			.catch(function(error) {});

		this.setState({
			stateMessageSent: true
		});

		event.preventDefault();
	}

	render() {
		return (
			<div className="container-fluid">
				<section className="row contact">
					<div className="container">
						<div className="row">
							<div className="col-md-12 pad">
								<h1>Contact Us</h1>
								<p>
									Get in touch for a demo of VU Live and to see the
									wider service offering.
								</p>
							</div>
							<div className="col-md-6 pad">
								<p>
									<strong>Aleda Denner</strong> <span className="contact-flag contact-flag-gb"></span>
								</p>
								<p>
									<strong>Telephone:</strong> +44 (0)20 7597
									5605
								</p>
							</div>
							<div className="col-md-6 pad">
								<p>
									<strong>Kevin Lim</strong> <span className="contact-flag contact-flag-gb"></span>
								</p>
								<p>
									<strong>Telephone:</strong> +44 (0)20 7597 4294
                                </p>
							</div>
							<div className="col-md-6 pad">
								<p>
									<strong>James Thomas</strong> <span className="contact-flag contact-flag-za"></span>
								</p>
								<p>
									<strong>Telephone:</strong> +27 (0)11 286 9983
								</p>
							</div>

							{this.state.stateMessageSent ? (
								<ContactMessageSent />
							) : (
								<div className="col-md-12 pad">
									<button id="show-form">Contact Us</button>
								</div>
							)}
						</div>

						{!this.state.stateMessageSent ? (
							<div className="row contact-form">
								<form onSubmit={this.handleSubmit}>
									<div className="form-row">
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="text"
													name="stateCompany"
													id="company"
													className="form-control"
													placeholder="Company"
													value={
														this.state.stateCompany
													}
													onChange={this.handleChange}
												/>
											</div>
											<div className="form-group">
												<input
													type="text"
													name="stateFirstName"
													id="first_name"
													className="form-control"
													placeholder="First name *"
													required
													value={
														this.state
															.stateFirstName
													}
													onChange={this.handleChange}
												/>
											</div>
											<div className="form-group">
												<input
													type="text"
													name="stateLastName"
													id="last_name"
													className="form-control"
													placeholder="Last name *"
													required
													value={
														this.state.stateLastName
													}
													onChange={this.handleChange}
												/>
											</div>
											<div className="form-group">
												<input
													type="email"
													name="stateEmail"
													id="email"
													className="form-control"
													placeholder="Email *"
													required
													value={
														this.state.stateEmail
													}
													onChange={this.handleChange}
												/>
											</div>
											<div className="form-group">
												<input
													type="text"
													name="stateContactNumber"
													id="contact_number"
													className="form-control"
													placeholder="Contact number"
													value={
														this.state
															.stateContactNumber
													}
													onChange={this.handleChange}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<textarea
												name="stateMessage"
												id="message"
												placeholder="Message *"
												className="form-control"
												value={this.state.stateMessage}
												onChange={this.handleChange}
											/>
										</div>
										<div className="col-md-12">
											<div className="form-group pad">
												<span className="pad">
													&nbsp;
												</span>
												<p>
													VU Live Share Plans may use
													my contact data to keep me
													informed of products,
													services and offerings:
												</p>
											</div>
											<div className="form-group">
												<label className="checkbox-container">
													<input
														name="stateByEmail"
														id="by_email"
														type="checkbox"
														value={
															this.state
																.stateByEmail
														}
														onChange={
															this.handleChange
														}
													/>
													by email
													<span className="checkmark" />
												</label>
											</div>
											<div className="form-group">
												<label className="checkbox-container">
													<input
														name="stateByTelephone"
														id="by_phone"
														type="checkbox"
														value={
															this.state
																.stateByTelephone
														}
														onChange={
															this.handleChange
														}
													/>
													by telephone
													<span className="checkmark" />
												</label>
											</div>

											<div className="form-group">
												<p className="">
													More information on our
													processing can be found in
													our{' '}
													<a href="https://www.investec.com/en_gb/legal/privacy-policy.html">
														Privacy Notice
													</a>
													.
												</p>
												<p className="">
													By submitting this form, I
													acknowledge that I have read
													and understood the{' '}
													<a href="https://www.investec.com/en_gb/legal/privacy-policy.html">
														Privacy Notice.
													</a>
												</p>
											</div>
											<div className="form-group">
												<button
													type="submit"
													id="btnSubmit"
												>
													Submit
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						) : (
							''
						)}
					</div>
				</section>
			</div>
		);
	}
}

export default Contact;
