import React, { Component } from 'react';
import ReactPlayerLoader from "@brightcove/react-player-loader";
class Intro extends Component {
	constructor() {
        super();

        this.state = { showVideo: false };
    }

	componentWillMount() {
        this.setState({
            showVideo: true,
        });
    }

	render() {
		let videoStyle = {
            position: "relative",
            display: "block",
            maxWidth: "100%",
        };

        let videoPositionStyle = {
            paddingTop: "56.25%",
        };

        let playerStyle = {
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            //visibility: 'hidden'
        };
		return (
			<div className="container">
				<section className="intro visible">
					<div className="row align-items-center">
						<article className="col-lg-7">
							<h2>
							A transformative, real time and  
								<br />
								totally interconnected share plans 
								<br />
								solution.  
							</h2>
							<p>
							<h4><strong>Experience the difference in user-driven technology and plan management controls</strong></h4>
							</p>
							<p>
							If managing your share plans is an administrative burden to you, or your employees are not feeling in control and empowered to manage their share plans, then Investec's VU Live share plan platform can help.
							</p>
							<p>
							VU Live is a <strong>self-service</strong> online platform, helping you manage your plans, with a clear audit trail and reporting to keep you in control
							</p>
							<p>
							VU Live is <strong>fully integrated</strong> with Investec's trading and settlements systems. This way, you don't have to rely on third-party brokers or settlement services, which helps deliver the best market trades and seamless straight through processing.
							</p>
							<p>
							Through VU Live your employees have <strong>direct access</strong> to their profiles, allowing them greater insight into their share plan holdings with the ability to transact in real time.
							  {/* They can see all of their information, download documents, view reports and trade their shares in real time. */}
							</p>
						</article>
						<article className="col-lg-5">
							<div style={videoStyle}>
								<div style={videoPositionStyle}>
									{this.state.showVideo ? (
										<div id="fixture" style={playerStyle}>
											<ReactPlayerLoader
												accountId="2176530276001"
												playerId="txktvlVxIo"
												videoId="6309572777112"
												embedId="default"
												applicationId
												controls
											/>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						</article>
					</div>
					{/*
					<div className="row align-items-center">
						<div className="col-sm-12">
							<img
								id="scroll"
								className="arrow-down mx-auto d-block"
								src="./images/arrow-down.png"
								alt="down arrow"
							/>
						</div>
					</div>
					*/}
				</section>
			</div>
		);
	}
}

export default Intro;
