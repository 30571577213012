import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <Link to="/">
                                <img
                                    className="logo img-fluid"
                                    src="./images/VU_Logo_BW.svg"
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="col-lg-8">
                            <div className="row justify-content-end logos">
                                <div className="col-lg-4">
                                    <img
                                        className="img-fluid float-lg-right px-0"
                                        src="./images/investec.png"
                                        alt="investec"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-lg-right">
                                    <ul>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>
                                            <a
                                                target="new"
                                                href="https://www.investec.com/en_gb/legal/terms-and-conditions.html"
                                            >
                                                Terms of Use
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="new"
                                                href="https://www.investec.com/en_gb/legal/privacy-policy.html"
                                            >
                                                Privacy
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="new"
                                                href="https://www.investec.com/en_gb/legal.html"
                                            >
                                                Legals - Investec
                                            </a>
                                        </li>
                                        <li className="pr-0">
                                            <Link to="/disclaimer">
                                                Disclaimer
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p className="copy text-lg-right">
                                        &copy; 2022 Investec Share Plan
                                        Services. All Rights Reserved.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
