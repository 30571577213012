import React, { Component } from "react";
import Config from "./config.json";
import { Link } from "react-router-dom";
import axios from "axios";
//const signalR = require("@aspnet/signalr");

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stateIsDeploying: true,
            stateDeploymentMessage: ""
        };
    }

    componentDidMount() {
        //this.ping();
        this.setState({
            stateIsDeploying: false
        });

        // var connection = new signalR.HubConnectionBuilder()
        //     .withUrl(Config.api.base + Config.api.srdeploy.endpoint)
        //     .build();

        // connection
        //     .start()
        //     .then(function() {
        //         console.log("Started");
        //     })
        //     .then(function() {
        //         connection.invoke("InitiateHeartBeat").catch(function(err) {
        //             return console.error("2", err.toString());
        //         });
        //     })
        //     .catch(function(err) {
        //         return console.error("1", err.toString());
        //     });

        // connection.on("IsDeploying", deploying => {
        //     this.setState({
        //         stateIsDeploying: deploying.isDeploy,
        //         stateDeploymentMessage: deploying.deploymentMessage
        //     });
        // });
    }

    ping = () => {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        axios
            .get(Config.api.base + Config.api.ping.endpoint, config)
            .then(response => {
                this.setState({
                    stateIsDeploying: response.data.isDeploy,
                    stateDeploymentMessage: response.data.deploymentMessage
                });
            })
            .catch(error => {
                this.setState({
                    stateIsDeploying: true
                });
            });
    };

    tryRequire = () => {
        let isDeploy = this.state.stateIsDeploying;
        if (isDeploy) {
            return true;
        }

        return false;
    };

    render() {
        const isDeploy = this.tryRequire() ? true : false;

        return (
            <header className="fixed-top">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link className="navbar-brand" to="/">
                            <img src="./images/VU_Logo_BW.svg" alt="logo" />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#menu"
                            aria-controls="menu"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div id="menu" className="navbar-collapse collapse">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="contact-us"
                                        href="#"
                                    >
                                        Contact us
                                    </a>
                                </li>
                                {isDeploy ? (
                                    ""
                                ) : (
                                    <li className="nav-item">
                                        <a
                                            className="nav-link"
                                            href={Config.api.login.endpoint}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Login
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default Menu;
