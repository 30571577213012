import React, { Component } from 'react';
import PrivacyNotice from './PrivacyNotice';
import Menu from './Menu';
import Footer from './Footer';
import Cookies from 'universal-cookie';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './Home';
import Disclaimer from './Disclaimer';
import Config from './config.json';

class App extends Component {
	cookies = new Cookies();

	constructor(props) {
		super(props);

		this.state = { cookieSaved: this.cookies.get('eucookie') };
	}

	render() {
		return (
			<BrowserRouter basename={Config.path.base}>
				<div className="App">
					{this.state.cookieSaved === 'eucookie' ? (
						''
					) : (
						<PrivacyNotice />
					)}
					<Menu />
					<Switch>
						<Route exact path="/" component={Home} />
						<Route path="/disclaimer" component={Disclaimer} />
					</Switch>
					<Footer />
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
